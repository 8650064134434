import React from "react"

import SEO from "../shared_components/seo"

const NotFoundPage = () => {
  return (
    <div>
      <SEO title="404 - Page Not Found" />
      <h1>404 - Page Not Found</h1>
    </div>
  )
}

export default NotFoundPage
